import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { pageColors, mediaMax } from 'styles';
import Flickity from 'react-flickity-component';
import arrowRightIcon from '../../assets/images/icons/arrowRight.png';

const DetailSliderContainer = styled.div``;

const SliderContainer = styled.div`
  overflow: hidden;
  position: relative;
  ${mediaMax(
    'xl',
    css`
      margin-top: 44px;
    `
  )}
  .flickity-enabled {
    :focus {
      outline: 0;
    }
  }
`;

const Slide = styled.div`
  width: 100%;
  user-select: none;
`;

const SlideImage = styled.div`
  padding-top: 75%;
  background-color: ${props => props.backgroundColor};
  background-image: url(${props => props.image});
  background-size: cover;
`;

const SlideCaption = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  line-height: 1.5em;
  font-size: 0.75em;
`;

const SliderInterfaceContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 75%;
  pointer-events: none;
`;

const nextSlideButtonSize = 64;

const Arrow = styled.div`
  position: absolute;
  width: 80%;
  height: 40%;
  top: 30%;
  left: ${props => (props.left ? 5 : 15)}%;
  background-image: url(${arrowRightIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: transform 0.15s ease-out;
  transform: rotate(${props => (props.left ? 180 : 0.001)}deg);
`;

const NextSlideButton = styled.div`
  pointer-events: all;
  position: absolute;
  z-index: 2;
  top: calc(50% - ${nextSlideButtonSize / 2}px);
  right: ${props => (props.left ? 'auto' : 0)};
  left: ${props => (props.left ? 0 : 'auto')};
  height: ${nextSlideButtonSize}px;
  width: ${nextSlideButtonSize / 2}px;
  border-top-left-radius: ${props => (props.left ? 0 : nextSlideButtonSize / 2)}px;
  border-bottom-left-radius: ${props => (props.left ? 0 : nextSlideButtonSize / 2)}px;
  border-top-right-radius: ${props => (props.left ? nextSlideButtonSize / 2 : 0)}px;
  border-bottom-right-radius: ${props => (props.left ? nextSlideButtonSize / 2 : 0)}px;
  background-color: ${pageColors.light1};
  cursor: pointer;
  transform-origin: ${props => (props.left ? 0 : 100)}% 50%;
  transform: scale(0.75);
  transition: transform 0.15s ease-out;
  &:hover {
    transform: scale(1);
    ${Arrow} {
      transform: scale(0.75) rotate(0.001deg);
      }
    }
  }
  &:first-of-type:hover {
    ${Arrow} {
        transform: scale(0.75) rotate(180deg);
    }
  }
`;

class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.flkty;
  }

  goToPrevSlide() {
    this.flkty.previous();
  }

  goToNextSlide() {
    this.flkty.next();
  }

  renderSlides(slides) {
    return slides.map((slide, index) => (
      <Slide key={index}>
        <SlideImage backgroundColor={slide.backgroundColor} image={slide.image} />
        <SlideCaption>{slide.caption}</SlideCaption>
      </Slide>
    ));
  }

  render() {
    const sliderOptions = {
      cellAlign: 'left',
      contain: false,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.05,
      friction: 0.5,
      dragThreshold: 5,
      wrapAround: true,
    };
    const { slides } = this.props;
    const sliderNeeded = slides.length > 1;
    return (
      <DetailSliderContainer>
        <SliderContainer>
          {!sliderNeeded && <div>{this.renderSlides(slides)}</div>}
          {sliderNeeded && (
            <Flickity flickityRef={c => (this.flkty = c)} options={sliderOptions}>
              {this.renderSlides(slides)}
            </Flickity>
          )}
          {sliderNeeded && (
            <SliderInterfaceContainer>
              <NextSlideButton key={'left'} left onClick={this.goToPrevSlide.bind(this)}>
                <Arrow left />
              </NextSlideButton>
              <NextSlideButton key={'right'} onClick={this.goToNextSlide.bind(this)}>
                <Arrow />
              </NextSlideButton>
            </SliderInterfaceContainer>
          )}
        </SliderContainer>
      </DetailSliderContainer>
    );
  }
}

export default hot(ImageSlider);
