import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { pageColors, Headline2, Headline3, mediaMax } from '../../styles';
import jonasPNG from '../../assets/images/portraits/jonas.png';
import christopherPNG from '../../assets/images/portraits/christopher.png';

const TeamContainer = styled.div`
  padding: 30px 0 80px;
  overflow: auto;
  overflow-x: hidden;
`;
const PersonColumn = styled.div`
  width: calc(50% - 18px);
  float: left;
  margin-left: ${props => props.offset * 36}px;
  ${mediaMax(
    'md',
    css`
      width: 100%;
      margin-left: 0;
      margin-bottom: 30px;
    `
  )}
`;
const Face = styled.div`
  background-color: #333;
  padding-top: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
`;
const CenterContainer = styled.div`
  text-align: center;
`;
const NameSticker = styled.div`
  display: inline-block;
  position: relative;
  min-width: 50%;
  margin-bottom: 10px;
  ${Headline3} {
    position: relative;
    z-index: 1;
    line-height: 1.25em;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: ${pageColors.medium1};
  }
`;
const NameStickerTopline = styled.div`
  position: relative;
  line-height: 1.5em;
  z-index: 1;
  font-size: 0.75em;
`;
const nameStickerOverflow = 15;
const NameStickerBackground = styled.div`
  background-color: ${pageColors.veryLight1};
  position: absolute;
  top: -${nameStickerOverflow}px;
  left: -${nameStickerOverflow}px;
  right: -${nameStickerOverflow}px;
  bottom: -${nameStickerOverflow}px;
  border-radius: 5px;
  box-shadow: 0px 6px 16px 0px rgba(52, 46, 68, 0.1);
`;

class Team extends Component {
  render() {
    return (
      <TeamContainer>
        <Headline2>The Team</Headline2>
        <PersonColumn>
          <Face image={jonasPNG} />
          <CenterContainer>
            <NameSticker>
              <NameStickerBackground />
              <NameStickerTopline>Hello, my name is</NameStickerTopline>
              <Headline3>Jonas</Headline3>
            </NameSticker>
          </CenterContainer>
          <p>
            and I’m a human like many others. I breathe, eat and shit. I write code to earn some money. I spend my money on bikes, alcohol and stuff to build
            other stuff.
          </p>
        </PersonColumn>
        <PersonColumn offset={1}>
          <Face image={christopherPNG} />
          <CenterContainer>
            <NameSticker>
              <NameStickerBackground />
              <NameStickerTopline>Hello, my name is</NameStickerTopline>
              <Headline3>Christopher</Headline3>
            </NameSticker>
          </CenterContainer>
          <p>and I am a developer for digital experiences. In my free time I like to expand out of the digital into haptic interfaces.</p>
        </PersonColumn>
      </TeamContainer>
    );
  }
}

export default hot(Team);
