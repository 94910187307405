export const layouts = [
  [
    [
      { c: '#605d6e', t: '#030303', p: 'DSA', sm: 'cherry', a: 7, f: 2 },
      'Esc',
      { a: 5, f: 3 },
      '!\n1',
      '@\n2',
      '#\n3',
      '$\n4',
      '%\n5',
      '^\n6',
      '&\n7',
      '*\n8',
      '(\n9',
      ')\n0',
      '_\n-',
      '+\n=',
      { c: '#857eb1', a: 7, f: 2, w: 2 },
      'Backspace',
    ],
    [{ c: '#605d6e' }, 'Tab', { f: 3 }, 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', { a: 5 }, '{\n[', '}\n]', '|\n\\', '~\n`'],
    [
      { a: 7, f: 2 },
      'Caps Lock',
      { f: 3 },
      'A',
      'S',
      'D',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      { a: 5 },
      ':\n;',
      '"\n\'',
      { c: '#857eb1', a: 7, f: 2, w: 2 },
      'Return',
      { c: '#605d6e' },
      'Up',
    ],
    [
      { c: '#857eb1', w: 2 },
      'Shift',
      { c: '#605d6e', f: 3 },
      'Z',
      'X',
      'C',
      'V',
      'B',
      'N',
      'M',
      { a: 5 },
      '<\n,',
      '>\n.',
      '?\n/',
      { a: 7, f: 2 },
      'Shift',
      { f: 3 },
      '↑',
      { f: 2 },
      'Down',
    ],
    ['Ctrl', 'Win', 'Alt', { c: '#6ca29d', p: 'DSA SPACE', w: 6 }, '', { c: '#605d6e', p: 'DSA' }, 'Alt', 'Fn', 'Ctrl', { f: 3 }, '←', '↓', '→'],
  ],
  [
    [
      { c: '#605d6e', t: '#030303', p: 'DSA', sm: 'cherry', a: 7, f: 2 },
      'Esc',
      { a: 5, f: 3 },
      '!\n1',
      '@\n2',
      '#\n3',
      '$\n4',
      '%\n5',
      '^\n6',
      '&\n7',
      '*\n8',
      '(\n9',
      ')\n0',
      '_\n-',
      '+\n=',
      { c: '#857eb1', a: 7, f: 2, w: 2 },
      'Backspace',
    ],
    [{ c: '#605d6e' }, 'Tab', { f: 3 }, 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', { a: 5 }, '{\n[', '}\n]', '~\n`', { a: 7, f: 2 }, 'Del'],
    [
      'Fn',
      { f: 3 },
      'A',
      'S',
      'D',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      { a: 5 },
      ':\n;',
      '"\n\'',
      { c: '#857eb1', a: 7, f: 2, w: 2 },
      'Return',
      { c: '#605d6e' },
      'Up',
    ],
    [
      { c: '#857eb1', w: 2 },
      'Shift',
      { c: '#605d6e', f: 3 },
      'Z',
      'X',
      'C',
      'V',
      'B',
      'N',
      'M',
      { a: 5 },
      '<\n,',
      '>\n.',
      '?\n/',
      '|\n\\',
      { a: 7 },
      '↑',
      { f: 2 },
      'Down',
    ],
    [
      { c: '#857eb1', w: 2 },
      'Ctrl',
      { c: '#605d6e' },
      'Win',
      'Alt',
      { c: '#6ca29d', p: 'DSA SPACE', w: 6 },
      '',
      { c: '#857eb1', p: 'DSA', w: 2 },
      'Fn',
      { c: '#605d6e', f: 3 },
      '←',
      '↓',
      '→',
    ],
  ],
  [
    [
      { c: '#605d6e', t: '#030303', p: 'DSA', sm: 'cherry', a: 5 },
      '~\n`',
      '!\n1',
      '@\n2',
      '#\n3',
      '$\n4',
      '%\n5',
      '^\n6',
      '&\n7',
      '*\n8',
      '(\n9',
      ')\n0',
      '_\n-',
      '+\n=',
      { a: 7, f: 2 },
      'Backspace',
      'Del',
    ],
    ['Tab', { f: 3 }, 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', { a: 5 }, '{\n[', '}\n]', '|\n\\', { a: 7, f: 2 }, 'UP'],
    ['Esc', { f: 3 }, 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', { a: 5 }, ':\n;', '"\n\'', { a: 7, f: 2 }, 'Return', 'Home', 'DOWN'],
    [
      { c: '#857eb1', w: 2 },
      'Shift',
      { c: '#605d6e', f: 3 },
      'Z',
      'X',
      'C',
      'V',
      'B',
      'N',
      'M',
      { a: 5 },
      '<\n,',
      '>\n.',
      '?\n/',
      { a: 7 },
      '↑',
      { f: 2 },
      'End',
      { c: '#857eb1', h: 2 },
      'Return',
    ],
    [
      { c: '#605d6e' },
      'Ctrl',
      'Fn',
      'Win',
      'Alt',
      { c: '#6ca29d', t: '#000000', w: 2 },
      '',
      { w: 2 },
      '',
      { c: '#605d6e', t: '#030303' },
      'Alt',
      'Fn',
      'Ctrl',
      { f: 3 },
      '←',
      '↓',
      '→',
    ],
  ],
  [
    [
      { c: '#605d6e', t: '#030303', p: 'DSA', sm: 'cherry', a: 7, f: 2 },
      'Esc',
      { a: 5, f: 3 },
      '!\n1',
      '@\n2',
      '#\n3',
      '$\n4',
      '%\n5',
      '^\n6',
      '&\n7',
      '*\n8',
      '(\n9',
      ')\n0',
      '_\n-',
      '+\n=',
      { c: '#857eb1', a: 7, f: 2, w: 2 },
      'Backspace',
    ],
    [{ c: '#605d6e' }, 'Tab', { f: 3 }, 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', { a: 5 }, '{\n[', '}\n]', '~\n`', { a: 7, f: 2 }, 'Del'],
    [
      'Fn',
      { f: 3 },
      'A',
      'S',
      'D',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      { a: 5 },
      ':\n;',
      '"\n\'',
      { c: '#857eb1', a: 7, f: 2, w: 2 },
      'Return',
      { c: '#605d6e' },
      'Up',
    ],
    [
      { c: '#857eb1', w: 2 },
      'Shift',
      { c: '#605d6e', f: 3 },
      'Z',
      'X',
      'C',
      'V',
      'B',
      'N',
      'M',
      { a: 5 },
      '<\n,',
      '>\n.',
      '?\n/',
      '|\n\\',
      { a: 7 },
      '↑',
      { f: 2 },
      'Down',
    ],
    [
      { c: '#857eb1', t: '#000000', w: 2 },
      'Ctrl',
      { w: 2 },
      'Alt',
      { c: '#6ca29d', p: 'DSA SPACE', w: 4 },
      '',
      { c: '#857eb1', p: 'DSA', w: 2 },
      'Alt',
      { w: 2 },
      'Fn',
      { c: '#605d6e', t: '#030303', f: 3 },
      '←',
      '↓',
      '→',
    ],
  ],
  [
    [
      { c: '#6c6680', t: '#030303', p: 'DSA', sm: 'cherry', a: 7 },
      'Esc',
      { a: 5 },
      '!\n1',
      '@\n2',
      '#\n3',
      '$\n4',
      '%\n5',
      '^\n6',
      '&\n7',
      '*\n8',
      '(\n9',
      ')\n0',
      '_\n-',
      '+\n=',
      { c: '#cc667d', a: 7, w: 2 },
      'Backspace',
    ],
    [{ c: '#6c6680' }, 'Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', { a: 5 }, '{\n[', '}\n]', '|\n\\', '~\n`'],
    [
      { a: 7 },
      'Caps Lock',
      'A',
      'S',
      'D',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      { a: 5 },
      ':\n;',
      '"\n\'',
      { c: '#a784b8', a: 7, w: 2 },
      'Return',
      { c: '#6c6680' },
      'Up',
    ],
    [
      { c: '#c9c799', t: '#000000' },
      'Shift',
      { c: '#6c6680' },
      'Z',
      'X',
      'C',
      'V',
      'B',
      'N',
      'M',
      { a: 5 },
      '<\n,',
      '>\n.',
      '?\n/',
      { c: '#c9c799', a: 7, w: 2 },
      'Shift',
      { c: '#6c6680' },
      '↑',
      'Down',
    ],
    [
      { c: '#7487c2', t: '#030303' },
      'Ctrl',
      { c: '#6c6680' },
      'FN',
      'Win',
      'Alt',
      { c: '#94b5a5', p: 'DSA SPACE', w: 2 },
      '',
      { w: 3 },
      '',
      { c: '#6c6680', p: 'DSA' },
      'Alt',
      'Fn',
      'Ctrl',
      '←',
      '↓',
      '→',
    ],
  ],
  [
    [
      { c: '#6c6680', t: '#030303', p: 'DSA', sm: 'cherry', a: 7 },
      'Esc',
      { a: 5 },
      '!\n1',
      '@\n2',
      '#\n3',
      '$\n4',
      '%\n5',
      '^\n6',
      '&\n7',
      '*\n8',
      '(\n9',
      ')\n0',
      '_\n-',
      '+\n=',
      { c: '#cc667d', a: 7 },
      'Backspace',
      { c: '#6c6680' },
      'Del',
    ],
    ['Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', { a: 5 }, '{\n[', '}\n]', '|\n\\', '~\n`'],
    [
      { a: 7 },
      'Caps Lock',
      'A',
      'S',
      'D',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      { a: 5 },
      ':\n;',
      '"\n\'',
      { c: '#a784b8', a: 7, w: 2 },
      'Return',
      { c: '#6c6680' },
      'Up',
    ],
    [
      { c: '#c9c799', w: 2 },
      'Shift',
      { c: '#6c6680' },
      'Z',
      'X',
      'C',
      'V',
      'B',
      'N',
      'M',
      { a: 5 },
      '<\n,',
      '>\n.',
      '?\n/',
      { c: '#c9c799', a: 7 },
      'Shift',
      { c: '#6c6680' },
      '↑',
      'Down',
    ],
    [
      { c: '#7487c2' },
      'Ctrl',
      { c: '#6c6680' },
      'Fn',
      'Win',
      'Alt',
      { c: '#94b5a5', p: 'DSA SPACE', w: 3 },
      '',
      { w: 2 },
      '',
      { c: '#6c6680', p: 'DSA' },
      'Alt',
      'Fn',
      'Ctrl',
      '←',
      '↓',
      '→',
    ],
  ],
]; // @TODO: we need some more layouts with split keycap and right shift variants
