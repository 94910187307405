import React, { Component } from 'react';
import styled from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { colors, pageColors } from 'styles';
import { Container, Row, Col } from 'react-grid-system';
import AnimatedKeyCap from './AnimatedKeyCap/';
import { TransitionGroup, Transition } from 'react-transition-group';
import hexToRgba from 'hex-to-rgba';
import { layouts } from './layouts';

const AUTOPLAY_INTERVAL = 1000 * 5;

const KeyboardContainer = styled.div`
  padding-top: 33.333%;
  position: relative;
  margin-bottom: 35px;
`;

const KeyboardCase = styled.div`
  background-color: ${pageColors.veryDark1};
  position: absolute;
  bottom: -2.25%;
  top: -2.25%;
  right: -0.75%;
  left: -0.75%;
  border-radius: 10px;
  box-shadow: 0px 50px 65px 0px ${hexToRgba(pageColors.veryDark1, 0.4)};
`;

const KeyContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const NavigationContainer = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
`;

const NavigationDot = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${props => (props.active ? pageColors.medium2 : pageColors.light1)};
  border-radius: 8px;
  display: inline-block;
  margin: 8px;
  cursor: ${props => (props.active ? 'auto' : 'pointer')};
`;

const commonModifierLegends = [
  'Esc',
  'Backspace',
  'Back',
  'Caps',
  'Caps Lock',
  'Up',
  'Shift',
  'Down',
  'Ctrl',
  'Win',
  'Alt',
  'Fn',
  'Ctrl',
  'Tab',
  '↑',
  '←',
  '↓',
  '→',
  'Os',
  'Return',
  'Enter',
  'Cmd',
  'Del',
  'Home',
  'End',
  'Page Up',
  'Page Down',
  'Pg Up',
  'Pg Down',
];

const AutoPlayPauseButton = styled.div`
  position: absolute;
  left: 0;
  cursor: pointer;
`;

class AnimatedKeyboard extends Component {
  constructor(props) {
    super(props);
    this.state = { activeLayout: 0, autoPlay: true };
    this.autoPlayInterval = null;
  }

  renderKeys(layouts, activeLayout) {
    const keys = [];
    let usedKeys = [];
    const findFreeKey = (legend, rowIndex, keyIndex) => {
      let hArea = commonModifierLegends.includes(legend) ? (keyIndex < 8 ? 'left' : 'right') : '';
      let newLegend = legend === '' ? 'Space' : legend;
      newLegend = newLegend + '_' + hArea + '_' + rowIndex;
      while (usedKeys.includes(newLegend)) {
        newLegend += '_joners';
      }
      usedKeys.push(newLegend);
      return newLegend;
    };
    let width = 1;
    let height = 1;
    layouts[activeLayout].forEach((row, rowIndex) => {
      let columnIndex = 0;
      row.forEach((legendOrObject, keyIndex) => {
        if (typeof legendOrObject === 'object') {
          if (typeof legendOrObject.w !== 'undefined') width = legendOrObject.w;
          else width = 1;
          if (typeof legendOrObject.h !== 'undefined') height = legendOrObject.h;
          else height = 1;
        } else {
          const key = findFreeKey(legendOrObject, rowIndex, columnIndex);
          const keyCapRowIndex = rowIndex;
          const keyColumnIndex = columnIndex;
          const keyCapWidth = width;
          const keyCapHeight = height;
          const keyCapLegend = legendOrObject;
          keys.push(
            <Transition timeout={1000} key={key}>
              {transitionState => (
                <AnimatedKeyCap
                  rowIndex={keyCapRowIndex}
                  columnIndex={keyColumnIndex}
                  width={keyCapWidth}
                  height={keyCapHeight}
                  legend={keyCapLegend}
                  transitionState={transitionState}
                />
              )}
            </Transition>
          );
          columnIndex += width;
        }
      });
    });
    return keys;
  }

  goToNextLayout() {
    const { activeLayout } = this.state;
    const nextLayoutIndex = activeLayout >= layouts.length - 1 ? 0 : activeLayout + 1;
    this.goToLayout(nextLayoutIndex);
  }

  goToLayout(index) {
    this.setState({ activeLayout: index });
  }

  renderNavigationDots(layouts, activeLayout) {
    return layouts.map((layout, index) => (
      <NavigationDot
        key={index}
        active={activeLayout == index}
        onClick={() => {
          this.goToLayout(index);
        }}
      />
    ));
  }

  startAutoPlay(autoPlay) {
    clearInterval(this.autoPlayInterval);
    if (autoPlay) {
      this.autoPlayInterval = setInterval(this.goToNextLayout.bind(this), AUTOPLAY_INTERVAL);
    }
  }

  handleAutoPlayButtonClick() {
    const newAutoPlay = !this.state.autoPlay;
    this.setState({ autoPlay: newAutoPlay });
    this.startAutoPlay(newAutoPlay);
  }

  componentDidMount() {
    this.startAutoPlay(this.state.autoPlay);
  }

  render() {
    const { autoPlay, activeLayout } = this.state;
    return (
      <div>
        <KeyboardContainer>
          <KeyboardCase />
          <KeyContainer>
            <TransitionGroup component={null}>{this.renderKeys(layouts, activeLayout)}</TransitionGroup>
          </KeyContainer>
        </KeyboardContainer>
        <NavigationContainer>
          <AutoPlayPauseButton onClick={this.handleAutoPlayButtonClick.bind(this)}>{autoPlay ? 'Pause' : 'Play'}</AutoPlayPauseButton>
          {this.renderNavigationDots(layouts, activeLayout)}
        </NavigationContainer>
      </div>
    );
  }
}

export default hot(AnimatedKeyboard);
