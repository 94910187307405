import React, { Component } from 'react';
import styled from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { colors, pageColors, boxedContainerStyleWithPadding, Headline2 } from 'styles';

const AnnouncementContainer = styled.div`
  overflow: visible;
  margin-top: 75px;
`;

class Announcement extends Component {
  render() {
    return (
      <AnnouncementContainer>
        <Headline2>Introducing 15X5</Headline2>
        <p>
          The name itself is a hint to one of the main features of the board. It’s an ortholinear keyboard with 15 columns and 5 rows. One issue of regular
          ortholinear keyboards is the lack of some bigger keys like the space bar, shift or enter key. The 15x5 is an attempt to fill the gap between a
          ortholinear and classic staggered keyboards. By adding some spots for switches between the columns, the 15x5 can provide a great amount of layout
          possibilities.
        </p>
      </AnnouncementContainer>
    );
  }
}

export default hot(Announcement);
