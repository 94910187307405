import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { colors, pageColors, baseFontStyle, Headline2, mediaMin } from 'styles';
import { Container, Row, Col, Visible } from 'react-grid-system';
import ImageSlider from './ImageSlider';
import { rows } from './Progress.data';

const ProgressContainer = styled.div`
  background-color: ${pageColors.veryDark1};
  padding: 5% 0;
`;

const ProgressRow = styled(Row)`
  padding: 80px 0;
  ${mediaMin(
    'l',
    css`
      padding: 120px 0;
    `
  )}
`;

const TextContainer = styled.div``;

const Topline = styled.span``;

const RowHeadline = styled(Headline2)`
  margin-top: 12px;
  font-size: 42px;
  line-height: 1em;
`;

class Progress extends Component {
  renderRows(rows) {
    const renderColumn = (row, index, cIndex) => {
      const even = index % 2 == 0;
      const reallyLeft = cIndex === 0;
      const left = even ? reallyLeft : !reallyLeft;
      const columnContent = left ? (
        <Visible md lg xl>
          <ImageSlider slides={row.imageColumn} />
        </Visible>
      ) : (
        <TextContainer>
          <Topline>{row.textColumn.topline}</Topline>
          <RowHeadline>{row.textColumn.headline}</RowHeadline>
          {row.textColumn.copyTop}
          <Visible xs sm>
            <ImageSlider slides={row.imageColumn} />
          </Visible>
          {row.textColumn.copyBottom}
        </TextContainer>
      );
      return (
        <Col key={cIndex} offset={{ xs: 1, md: reallyLeft ? 0 : 1, lg: 1, xl: 1 }} xs={13} md={left ? 7 : 7} lg={left ? 7 : 5} xl={left ? 8 : 4}>
          {columnContent}
        </Col>
      );
    };

    return rows.map((row, index) => (
      <ProgressRow key={index}>
        {renderColumn(row, index, 0)}
        {renderColumn(row, index, 1)}
      </ProgressRow>
    ));
  }

  render() {
    return (
      <ProgressContainer>
        <Container>
          <Row>
            <Col offset={{ xs: 1 }} xs={13}>
              <Headline2>Progress</Headline2>
            </Col>
          </Row>
          {this.renderRows(rows)}
        </Container>
      </ProgressContainer>
    );
  }
}

export default hot(Progress);
