import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { pageColors, mediaMax } from 'styles';
import { Col, Container, Row, Visible } from 'react-grid-system';
import Announcement from './Announcement';
import AnimatedKeyboard from '../AnimatedKeyboard';
//import { Parallax } from 'react-scroll-parallax';
import logo15x5Path from '../../assets/images/15x5.svg';
//import renderPcbFlatBig from '../../assets/images/render_pcb_flat_big.png';

const AboutKeyBoardContainer = styled.div`
  background-color: ${pageColors.dark1};
  padding-bottom: 5%;
`;

const TopRow = styled(Row)`
  padding-top: 2.5%;
  padding-bottom: 5%;
`;

const Path = styled(logo15x5Path)`
  margin-top: 85px;
  ${mediaMax(
    'xl',
    css`
      margin-top: 95px;
    `
  )}
  ${mediaMax(
    'md',
    css`
      margin-top: 20px;
      margin-bottom: 30px;
    `
  )}
  fill: ${pageColors.medium1};
  .cls-1 {
    stroke: ${pageColors.veryDark1};
    stroke-width: 80px;
  }
`;

class AboutKeyBoard extends Component {
  renderLogoColumn() {
    return (
      <Col offset={{ xs: 4, md: 1, lg: 2 }} xs={7} md={5} lg={3}>
        <Path />
      </Col>
    );
  }

  render() {
    return (
      <AboutKeyBoardContainer>
        <Container>
          <TopRow>
            <Visible md lg xl>
              {this.renderLogoColumn()}
            </Visible>
            <Col offset={{ xs: 1, md: 1 }} xs={13} md={7}>
              <Announcement />
            </Col>
            <Visible xs sm>
              {this.renderLogoColumn()}
            </Visible>
          </TopRow>
          <Row>
            <Col xs={15}>
              <AnimatedKeyboard />
            </Col>
          </Row>
        </Container>
      </AboutKeyBoardContainer>
    );
  }
}

export default hot(AboutKeyBoard);
