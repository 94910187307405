import React, { Component } from 'react';
import explodeImage from '../../assets/images/slider/explode.jpg';
import prototypingImage1 from '../../assets/images/slider/p1.jpg';
import prototypingImage2 from '../../assets/images/slider/p2.jpg';
import renderImage1 from '../../assets/images/slider/r1.jpg';
import renderPCBImage1 from '../../assets/images/slider/rpcb1.png';
import renderPCBImage2 from '../../assets/images/slider/rpcb2.png';
import dsaKeysetImage1 from '../../assets/images/slider/dsaKeyset.jpg';
import bildschirmfotoImage from '../../assets/images/slider/bildschirmfoto.jpg';
import kicadImage from '../../assets/images/slider/kicad.jpg';
import checkBoxIcon from '../../assets/checkbox.png';
import checkBoxCheckedIcon from '../../assets/checkbox_checked.png';
import styled from 'styled-components';
import { colors, pageColors, baseFontStyle, Headline2, A, Span } from 'styles';

const P = styled.p``;

const UL = styled.ul`
  list-style: none;
  padding-inline-start: 0;
`;

const LI = styled.li`
  text-decoration: ${props => (props.strike ? 'line-through' : 'none')};
  text-decoration-color: ${pageColors.medium2};
  background-image: url(${props => (props.check ? checkBoxCheckedIcon : checkBoxIcon)});
  background-size: 14px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  padding-left: 22px;
  margin-bottom: 7px;
`;

export const rows = [
  {
    imageColumn: [
      {
        image: bildschirmfotoImage,
        caption: 'keyboard-layout-editor.com is where ideas are born.',
      },
      { image: renderImage1, caption: 'The 15x5 will foremost be a pcb. Case just for idea visualisation.' },
    ],
    textColumn: {
      topline: 'Idea and first drafts of different layouts',
      headline: 'The first steps',
      copyTop: (
        <div>
          <P>
            For a long time we were looking into building ortholinear keyboards. But we never got used to the idea of sacrificing bigger modifier keys and a big
            ass spacebar.{' '}
          </P>
        </div>
      ),
      copyBottom: (
        <div>
          <P>
            <b>So the idea was born:</b> An ortholinear keyboard layout, but with bigger modifiers which work in a grid.
          </P>
          <P>
            Both convinced by this idea, we still were argueing about how a perfect layout in this grid might look like. So decided to enable as many
            possibilities as we can with this board.
          </P>
          <P>
            <Span>Reddit discussion about the layout: </Span>
            <A href="https://www.reddit.com/r/MechanicalKeyboards/comments/9rxv1c/a_ortholinear_layout_i_made_i_was_wondering_if/" target="_blank">
              <Span>Link</Span>
            </A>
          </P>
        </div>
      ),
    },
  },
  {
    imageColumn: [
      {
        image: kicadImage,
        caption: 'KiCad can be intimidating.',
      },
      {
        image: renderPCBImage2,
        caption: "We're trying to enable a lot of layout possibilities. Not easy if you also have to consider stabilizer and standoff positions.",
        backgroundColor: pageColors.dark2,
      },
    ],
    textColumn: {
      topline: 'Designing and planning',
      headline: 'Wow, this can be possible!',
      copyTop: (
        <div>
          <P>
            <Span>There seemed no way around it: We needed to learn more about electronics and pcb designing. A good starting point is </Span>
            <A target="_blank" href="https://github.com/ruiqimao/keyboard-pcb-guide">
              <Span>this wonderful tutorial</Span>
            </A>{' '}
            <Span>by github user ruiqimao.</Span>
          </P>
        </div>
      ),
      copyBottom: (
        <div>
          <P>Design decisions:</P>
          <UL>
            <LI check>1u and 2u modifiers on the left and right side and on the bottom row</LI>
            <LI check>A lot of spacebar positions including different sizes split variants</LI>
            <LI check>Right-shift and left-shift dominant layouts</LI>
            <LI check>Numpad style vertical enter key</LI>
            <LI check>PCB stabilizer mounts</LI>
          </UL>
        </div>
      ),
    },
  },
  {
    imageColumn: [
      {
        image: renderPCBImage1,
        caption: "We're not final on colors or finishes, sleek and clean would be nice.",
        backgroundColor: pageColors.dark2,
      },
      {
        image: prototypingImage1,
        caption: 'Good Times: Working on the first prototype pcb.',
      },
      {
        image: prototypingImage2,
        caption: 'Closeup of the processor: atmega32u4',
      },
    ],
    textColumn: {
      topline: 'Soldering and flashing',
      headline: 'The first prototype',
      copyTop: (
        <P>
          For limiting expenses during prototyping we chose to assemble ourselves for now. SMD soldering was new to us, but once we got used to solder masks and
          the heat gun, the whole process went from intimidating to absolutely enjoyable.
        </P>
      ),
      copyBottom: <P>Sadly we discovered some issues with our design in this first batch. But that's what prototypes are made for.</P>,
    },
  },
  {
    imageColumn: [
      {
        image: explodeImage,
        caption:
          'Exploded view of the case components. As written before: The case is a job for later. If someone is talented and interested: We can provide specs /w sizes and standoff positions.',
      },
      {
        image: dsaKeysetImage1,
        caption:
          'Some DSA keyset we pieced together for prototyping. PimpMyKeyboard offers custom sized caps like these 2u modifiers and special spacebar sizes.',
      },
    ],
    textColumn: {
      topline: 'Next steps',
      headline: 'The future',
      copyBottom: (
        <div>
          <P>There still are some things to do:</P>
          <UL>
            <LI check strike>
              PCB design
            </LI>
            <LI check strike>
              PCB first prototype
            </LI>
            <LI check strike>
              Website
            </LI>
            <LI>Fix some issues with the pcb design</LI>
            <LI>Another PCB prototype</LI>
            <LI>Also - even if it is a very simple one - we need a case to house our board.</LI>
            <LI>Interest check</LI>
            <LI>Group buy</LI>
          </UL>
        </div>
      ),
    },
  },
];
