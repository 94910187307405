import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { Headline2, pageColors, A, Span, linkStyle, mediaMax, mediaMin, mediaFromTo } from 'styles';
import { Col, Container, Row, Visible } from 'react-grid-system';
import DiscordIcon from '../../assets/discord_icon.svg';
import InstagramIcon from '../../assets/instagram_icon.svg';

const FollowUpContainer = styled.div`
  background-color: ${pageColors.dark1};
  padding: 50px 0 0;
  position: relative;
`;

const Headline = styled(Headline2)`
  margin-top: 40px;
  margin-bottom: 80px;
  text-align: center;
`;

const socialLogoStyle = color => css`
  path,
  line,
  circle {
    fill: ${color};
  }
`;

const coloredBackgroundStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
`;

const ColoredBackgroundLeft = styled.a`
  ${coloredBackgroundStyle}
  background: ${pageColors.light1};
  right: 50%;
  left: 0;
  ${mediaMax(
    'lg',
    css`
      right: 0;
      bottom: 50%;
    `
  )}
`;

const ColoredBackgroundRight = styled.a`
  ${coloredBackgroundStyle}
  background: ${pageColors.medium1};
  right: 0;
  left: 50%;
    ${mediaMax(
      'lg',
      css`
        left: 0;
        top: 50%;
      `
    )}
`;

const DiscordLogo = styled(DiscordIcon)`
  ${socialLogoStyle(pageColors.dark1)}
`;

const InstagramLogo = styled(InstagramIcon)`
  ${socialLogoStyle(pageColors.light1)}
`;

const DiscordText = styled.p`
  color: ${pageColors.dark1};
  ${mediaFromTo(
    'lg',
    'xl',
    css`
      margin: 0;
    `
  )}
`;

const InstagramText = styled.p``;

const Relative = styled.div`
  position: relative;
  padding: 80px 0;
`;

const SocialContainer = styled(Container)`
  pointer-events: none;
`;

const LogoContainer = styled.div`
  width: 40%;
  height: 100%;
  float: left;
  ${mediaMin(
    'md',
    css`
      margin-right: 10%;
    `
  )}
  ${mediaMax(
    'lg',
    css`
      width: ${(3 * 100) / 11}%;
    `
  )}
  ${mediaMax(
    'md',
    css`
      width: ${(7 * 100) / 11}%;
      margin-left: ${(2 * 100) / 11}%;
      height: auto;
      float: none;
      margin-bottom: 40px;
    `
  )}
`;

const DiscordCol = styled(Col)`
  ${mediaMax(
    'lg',
    css`
      margin-bottom: 80px;
    `
  )}
`;

const InstagramCol = styled(Col)`
  ${mediaMax(
    'lg',
    css`
      margin-top: 80px;
    `
  )}
`;

class FollowUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftHover: false,
      leftClick: false,
      rightHover: false,
      rightClick: false,
    };
  }

  render() {
    return (
      <FollowUpContainer>
        <Container>
          <Row>
            <Col offset={{ xs: 2, lg: 1 }} xs={11} lg={13}>
              <Headline>Follow up</Headline>
            </Col>
          </Row>
        </Container>
        <Relative>
          <ColoredBackgroundRight target="_blank" href="https://www.instagram.com/futur_click/" />
          <ColoredBackgroundLeft target="_blank" href="https://discord.gg/ZakFym" />
          <SocialContainer>
            <Row>
              <DiscordCol offset={{ xs: 2, lg: 1 }} xs={11} lg={5}>
                <LogoContainer>
                  <DiscordLogo />
                </LogoContainer>
                <DiscordText>
                  Interested? Nice! And if you like to share your thoughts and ideas on 15x5, if you have feedback or if you just want to talk: Feel free to
                  join our new Discord.
                </DiscordText>
              </DiscordCol>
              <InstagramCol offset={{ xs: 2, lg: 3 }} xs={11} lg={5}>
                <LogoContainer>
                  <InstagramLogo />
                </LogoContainer>
                <InstagramText>We also invite you to follow our Instagram. Never miss a progress update or nice 15x5 render.</InstagramText>
              </InstagramCol>
            </Row>
          </SocialContainer>
        </Relative>
      </FollowUpContainer>
    );
  }
}

export default hot(FollowUp);
