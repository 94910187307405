import React, { Component } from 'react';
import styled from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { colors, newColors, pageColors, easeHard } from '../../styles';
import logoPath from '../../assets/images/Logers.svg';

const Container = styled.div`
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 42%;
  margin: 100px 0;
`;

const Path = styled(logoPath)`
  position: absolute;
  top: 25%;
  path {
    stroke-width: 80px;
    stroke: ${newColors.pineApple};
    fill: none;
  }
`;

const AnimatedPath = styled(logoPath)`
  position: absolute;
  top: 25%;
  z-index: ${props => props.z};
  path {
    stroke-width: ${props => props.z + 80}px;
    stroke: ${props => props.linecolor};
    fill: none;
    stroke-dasharray: 1600;
    stroke-dashoffset: ${props => props.do};
    transition: stroke-dashoffset 1s ${easeHard};
  }
`;

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentDidMount() {
    if (!this.state.show) this.setState({ show: true });
  }

  render() {
    const { highlight } = this.props;
    const { show } = this.state;
    return (
      <Container>
        <Path />
        <AnimatedPath z={1} key={'base'} linecolor={newColors.middleRed} do={show && !highlight ? 0 : 1600} />
      </Container>
    );
  }
}

export default hot(Logo);
