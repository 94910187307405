import styled, { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { css } from 'styled-components';
import regularWoff from 'assets/AlegreyaSans/alegreyasans-regular-webfont.woff'
import regularWoff2 from 'assets/AlegreyaSans/alegreyasans-regular-webfont.woff2'
import regularTtf from 'assets/AlegreyaSans/AlegreyaSans-Regular.ttf'

import boldWoff from 'assets/AlegreyaSans/alegreyasans-bold-webfont.woff'
import boldWoff2 from 'assets/AlegreyaSans/alegreyasans-bold-webfont.woff2'
import boldTtf from 'assets/AlegreyaSans/AlegreyaSans-Bold.ttf'

import blackWoff from 'assets/AlegreyaSans/alegreyasans-black-webfont.woff'
import blackWoff2 from 'assets/AlegreyaSans/alegreyasans-black-webfont.woff2'
import blackTtf from 'assets/AlegreyaSans/AlegreyaSans-Black.ttf'

const fontStyles = `
  @font-face {
    font-family: 'Alegreya Sans';
    src: url('${blackWoff}') format('woff2'), /* Super Modern Browsers */
         url('${blackWoff2}') format('woff'), /* Pretty Modern Browsers */
         url('${blackTtf}')  format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: 900;
    font-display: swap;
  }
  @font-face {
    font-family: 'Alegreya Sans';
    src: url('${boldWoff}') format('woff2'), /* Super Modern Browsers */
         url('${boldWoff2}') format('woff'), /* Pretty Modern Browsers */
         url('${boldTtf}')  format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: 'Alegreya Sans';
    src: url('${regularWoff2}') format('woff2'), /* Super Modern Browsers */
         url('${regularWoff}') format('woff'), /* Pretty Modern Browsers */
         url('${regularTtf}')  format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
`;

const fontStyleTag = document.createElement('style');
fontStyleTag.type = 'text/css';
fontStyleTag.innerHTML = fontStyles;
document.getElementsByTagName('head')[0].appendChild(fontStyleTag);

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 1140,
  xl: 1500,
};

export const containerWidths = {
  sm: 540,
  md: 720,
  lg: 1080,
  xl: 1440,
};

export const mediaMax = (breakpoint, style) => {
  return css`
    @media (max-width: ${breakpoints[breakpoint]}px) {
      ${style}
    }
  `;
};

export const mediaMin = (breakpoint, style) => {
  return css`
    @media (min-width: ${breakpoints[breakpoint]}px) {
      ${style}
    }
  `;
};

export const mediaFromTo = (fromBreakpoint, toBreakpoint, style) => {
  return css`
    @media (min-width: ${breakpoints[fromBreakpoint]}px) and (max-width: ${breakpoints[toBreakpoint]}px) {
      ${style}
    }
  `;
};

export const linkStyle = (color, hoverColor, hoverBackgroundColor) => css`
  color: ${color};
  &:hover {
    color: ${hoverColor};
    &::before {
      background-color: ${hoverBackgroundColor};
    }
  }
`;

export const colors = {
  purple: '#3a344d',
  lightTeal: '#7bf0c5',
  almostWhite: '#f0f0f1',
};

export const newColors = {
  jet: '#342E44',
  pineApple: '#4A4163',
  lapisLazuli: '#3369AB',
  middleRed: '#E78778',
  champagnePunk: '#EFD7D7',
  mediumAquamarine: '#5CC9B0',
};

export const pageColors = {
  veryDark1: newColors.jet,
  dark1: newColors.pineApple,
  dark2: newColors.lapisLazuli,
  medium1: newColors.middleRed,
  medium2: newColors.mediumAquamarine,
  light1: newColors.champagnePunk,
  veryLight1: '#f2e6e6',
};

export const containerStyle = css`
  margin: 40px 0;
  padding: 40px 0;
  overflow: hidden;
  position: relative;
`;

export const boxedContainerStyle = css`
    ${containerStyle}
    background-color: ${pageColors.medium2};
    color: ${pageColors.dark1};
    border-radius: 10px;
    padding: 0;
`;

export const boxedContainerPadding = css`
  padding: 40px ${100 / 7}%;
`;

export const boxedContainerStyleWithPadding = css`
  ${boxedContainerStyle}
  ${boxedContainerPadding}
`;

export const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
    @keyframes animatedPath {
        0% {
            stroke-dashoffset: 1500;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
`;

const textSelectionStyle = css`
  &::selection {
    background-color: ${pageColors.light1};
    color: ${pageColors.dark2};
  }
`;

export const baseFontStyle = css`
  ${textSelectionStyle}
  font-family: "Alegreya Sans";
  letter-spacing: 0.05em;
  line-height: 1.5em;
  color: ${pageColors.light1};
  p,
  span,
  ul,
  li {
    ${textSelectionStyle}
  }
`;

export const headlineStyle = css`
  ${textSelectionStyle}
  font-family: "Alegreya Sans";
  font-weight: 900;
  text-transform: uppercase;
`;

export const Headline2 = styled.h2`
  ${headlineStyle}
`;

export const Headline3 = styled.h3`
  ${headlineStyle}
`;

export const A = styled.a`
  display: inline-block;
  position: relative;
  color: ${pageColors.medium1};
  font-weight: 700;
  text-decoration: none;
  &:hover {
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1.1);
      background-color: ${pageColors.dark1};
      border-radius: 5px;
      z-index: 0;
    }
  }
`;

export const Span = styled.span`
  position: relative;
  z-index: 1;
`;

export const easeHard = 'cubic-bezier(0.285, 0.000, 0.145, 1.000)';
