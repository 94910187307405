import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { pageColors, baseFontStyle, easeHard, A, linkStyle, mediaMax, mediaFromTo } from 'styles';
import { Container, Row, Col } from 'react-grid-system';
import Logo from './Logo';
import Team from './Team';
import AboutUs from './AboutUs';

const HeaderContainer = styled.div`
  position: relative;
  background-color: ${props => (props.open ? pageColors.light1 : pageColors.veryDark1)};
  overflow: hidden;
  color: ${pageColors.veryDark1};
  ${A} {
    ${linkStyle(pageColors.dark2, pageColors.veryDark1, pageColors.medium1)}
  }
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  height: ${props => props.height}px;
  transition: ${props => (props.resizing ? 'none' : `height 1s ${easeHard}, background-color 1s ${easeHard}`)};
`;

const Above = styled.div`
  position: relative;
`;

const Below = styled.div``;

const AboutButton = styled.div`
  position: absolute;
  height: 50px;
  width: ${(3 * 100) / 15}%;
  text-align: center;
  right: 0;
  box-sizing: border-box;
  bottom: 0;
  background-color: ${pageColors.dark1};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 12px 20px 0;
  color: ${pageColors.veryLight1};
  transition: transform 1s ${easeHard};
  // transform: translate(0, ${props => props.y}px);
  cursor: pointer;
  z-index:2;
  display: none;
`;

const ButtonContainer = styled(Container)`
  position: absolute !important; // what is so important here?
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MoreButton = styled.div`
  position: absolute;
  right: ${(1 * 100) / 15}%;
  width: ${(2 * 100) / 15}%;
  background-color: ${props => (props.open ? pageColors.medium1 : pageColors.dark1)};
  color: ${props => (props.open ? pageColors.light1 : pageColors.veryDark1)};
  text-align: center;
  padding: 10px 30px;
  box-sizing: border-box;
  border-radius: 30px;
  height: 50px;
  line-height: 30px;
  top: calc(50% - 25px);
  font-weight: bold;
  transition: background-color 1s, color 1s;
  cursor: pointer;
  ${mediaFromTo(
    'lg',
    'xl',
    css`
      width: ${(3 * 100) / 15}%;
      right: 0;
    `
  )}
  ${mediaMax(
    'lg',
    css`
      display: none;
      width: ${(4 * 100) / 15}%;
      right: 0;
    `
  )}
`;

class Home extends Component {
  getHeight(open) {
    if (this.aboveRef.current && this.belowRef.current) {
      const aHeight = this.aboveRef.current.offsetHeight;
      const bHeight = this.belowRef.current.offsetHeight;
      return open ? aHeight + bHeight : aHeight;
    } else return 0;
  }

  constructor(props) {
    super(props);
    this.state = { open: false, height: 0 };
    this.aboveRef = React.createRef();
    this.belowRef = React.createRef();
    this.resizeTimeout;
    this.resizeEndTimeout;
  }

  onWindowResize() {
    clearTimeout(this.resizeTimeout);
    clearTimeout(this.resizeEndTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.updateHeight(() => {
        this.setState({ resizing: true });
      });
    }, 0);
    this.resizeEndTimeout = setTimeout(() => {
      this.setState({ resizing: false });
    }, 10);
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.onWindowResize.bind(this));
    this.onWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize.bind(this));
    clearTimeout(this.resizeTimeout);
    clearTimeout(this.resizeEndTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.height !== prevState.height) {
      this.updateHeight();
    }
  }

  updateHeight(callback = () => {}) {
    this.setState({ height: this.getHeight(this.state.open) }, callback);
  }

  handleClick() {
    console.log('klick');
    this.setState({ open: !this.state.open }, this.updateHeight);
  }

  render() {
    const { height, open, resizing } = this.state;
    return (
      <HeaderContainer height={height} open={open} resizing={resizing}>
        <Container>
          <Above ref={this.aboveRef}>
            <Row>
              <Col offset={{ xs: 3, md: 5 }} xs={9} md={5} onClick={this.handleClick.bind(this)}>
                <Logo highlight={open} />
              </Col>
              <MoreButton open={open} onClick={this.handleClick.bind(this)}>
                {open ? 'Close' : 'Find out more'}
              </MoreButton>
            </Row>
          </Above>
          <Below ref={this.belowRef}>
            <Row>
              <Col offset={{ xs: 1, md: 0 }} xs={13} md={7}>
                <AboutUs />
              </Col>
              <Col offset={{ xs: 1, md: 1 }} xs={13} md={7}>
                <Team />
              </Col>
            </Row>
          </Below>
        </Container>
        {/*  <ButtonContainer>
          <AboutButton y={height} onClick={this.handleClick.bind(this)}>
            Learn more about FUTUR
          </AboutButton>
        </ButtonContainer> */}
      </HeaderContainer>
    );
  }
}

export default hot(Home);
