import React from 'react';
import ReactDOM from 'react-dom';
import Home from 'components/Home'

const root = document.createElement('div');
document.body.appendChild(root);

ReactDOM.render(
    <Home />,
    root
);