import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { colors, containerStyle, Headline2, Headline3, A, Span, mediaMax } from '../../styles';

const AboutUsContainer = styled.div`
  padding: 30px 0 80px;
  ${mediaMax(
    'md',
    css`
      padding-bottom: 30px;
    `
  )}
`;

const TextColumns = styled.div`
  columns: 2;
  column-gap: 35px;
  ${mediaMax(
    'md',
    css`
      columns: 1;
    `
  )}
`;

const Paragraph = styled.p`
  margin-top: 0;
`;

class AboutUs extends Component {
  render() {
    return (
      <AboutUsContainer>
        <Headline2>Hello</Headline2>
        <TextColumns>
          <Paragraph>We're an independent hobbyist duo striving to create truly next gen input devices.</Paragraph>
          <Paragraph>
            <Span>
              We both work as developers at a Hamburg (Germany) based{' '}
              <A target="_blank" href="https://https://demodern.com/">
                <Span>digital studio</Span>
              </A>
              . Early on in our office bromance, we found our common fascination for haptic interfaces and input devices. That's why custom keyboards were a no
              brainer and so we started inspriring each other to build{' '}
            </Span>
            <A
              target="_blank"
              href="https://www.keebtalk.com/t/looking-for-experience-3d-printing-polyamid-nylon-in-sls-laser-sintering-for-custom-case/4944/12"
            >
              <Span>cool keyboards</Span>
            </A>
            <Span> and even a </Span>
            <A target="_blank" href="https://www.reddit.com/r/MechanicalKeyboards/comments/7kei5y/custom_nidhogg_game_controller_prototype_photos/">
              <Span>game controller.</Span>
            </A>
          </Paragraph>
          <Paragraph>
            Since we soon realized that a custom keyboard can indeed be very custom, it would never be truly what we were imagining. So we decided to get into
            designing, experimenting and (hopefully soon) producing what we are dreaming about.
          </Paragraph>
          <Paragraph>For now, this page is mostly about the 15x5, but will show more projects in the future.</Paragraph>
        </TextColumns>
      </AboutUsContainer>
    );
  }
}

export default hot(AboutUs);
