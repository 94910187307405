import React, { Component } from "react";
import styled, { css } from "styled-components";
import { hot } from "react-hot-loader/root";
import { mediaMax, mediaMin, pageColors, easeHard } from "styles";
import { Container, Row, Col, Visible } from "react-grid-system";

const keyCapColors = {
  base: pageColors.dark1,
  highlight: pageColors.light1,
  u2: pageColors.medium1,
  space: pageColors.medium2,
  font: pageColors.light1,
};

const entering = css`
  opacity: 0;
  transform: scale(0);
`;

const entered = css`
  opacity: 1;
  transform: scale(1);
`;

const exiting = css`
  opacity: 0;
  transform: scale(0);
`;

const exited = css`
  opacity: 0;
  transform: scale(0);
`;

const KeyOuterComponent = styled.div`
  position: absolute;
  width: calc(100% / ${props => 15 / props.width});
  height: calc(100% / ${props => 5 / props.height});
  left: ${props => props.columnIndex * (100 / 15)}%;
  top: ${props => props.rowIndex * (100 / 5)}%;
  padding: 3px;
  ${mediaMax('md', css`
      padding: 2px;
  `)}
  ${mediaMax('xs', css`
      padding: 1px;
  `)}
  box-sizing: border-box;
  user-select: none;
  transition: all 0.5s ${easeHard} 0.5s, transform 0.5s ${easeHard} 0s, padding 0s, opacity 0.5s ${easeHard} 0s;
  ${props => {
    switch (props.transitionState) {
      case "entering":
        return entering;
      case "entered":
        return entered;
      case "exiting":
        return exiting;
      case "exited":
      default:
        return exited;
    }
  }}
`;

const Cap = styled.div`
  position: relative;
  background-color: ${props => props.color};
  border-radius: 5px;
  height: 100%;
  padding: 7px;
  ${mediaMax('md', css`
      padding: 5px;
  `)}
  ${mediaMax('sm', css`
      padding: 3px;
  `)}
  box-sizing: border-box;
  transition: background-color 0.5s ${easeHard} 0.5s, transform 0.08s ${easeHard}, padding 0s;
  transform: scale3d(${props=>props.pressed ? 1 - (0.05/props.width) : 1},${props=>props.pressed ? 1 - (0.05/props.height) : 1},1) rotate(0.001deg);
`;

const CapTop = styled.div`
  background-color: white;
  opacity: 0.1;
  border-radius: 3px;
  height: 100%;
  position: relative;
`;

const LegendContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 7px;
  left: 7px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  box-sizing: border-box;
  text-transform: uppercase;
  font-style: italic;
  font-size: 8px;
  color: ${keyCapColors.font};
  overflow: hidden;
  border-radius: 3px;
`;

const Legend = styled.div`
  position: absolute;
  width: 100%;
  top: ${props => props.position === 'top' ? '9%' : props.position === 'middle' ? 'calc(50% - 12px)' : 'auto'};
  bottom: ${props => props.position === 'bottom' ? '9%' : 'auto'};
  ${props => mediaMax('lg', css`
      display: ${props.position === 'top' ? 'none' : 'block'};
      top: calc(50% - 12px);
      bottom: auto;
  `)}
`;

class AnimatedKeyCap extends Component {

  constructor(props) {
    super(props);
    this.state = {pressed: false};
  }

  handleKeyPress() {
    this.setState({pressed: true});
  }

  handleKeyRelease() {
    this.setState({pressed: false});
  }

  renderLegends(legends) {
    const twoLegends = legends.length == 2;
    return legends.map((legend, index) => {
      const position = twoLegends ? index === 0 ? 'top' : 'bottom' : 'middle';
      return <Legend key={index} position={position}>{legend}</Legend>
    });
  }

  render() {
    const {
      rowIndex,
      columnIndex,
      width,
      height,
      legend,
      transitionState
    } = this.props;
    const {pressed} = this.state;
    const keyColor = width > 1 || height > 1 ? legend === "" ? keyCapColors.u2 : keyCapColors.space : keyCapColors.base;
    return (
      <KeyOuterComponent
        transitionState={transitionState}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        width={width}
        height={height}
        onMouseDown={this.handleKeyPress.bind(this)}
        onTouchStart={this.handleKeyPress.bind(this)}
        onMouseUp={this.handleKeyRelease.bind(this)}
        onTouchEnd={this.handleKeyRelease.bind(this)}
        onMouseLeave={this.handleKeyRelease.bind(this)}
        onTouchCancel={this.handleKeyRelease.bind(this)}
      >
        <Cap color={keyColor} pressed={pressed} width={width} height={height}>
          <CapTop />
          <Visible sm md lg xl>
            <LegendContainer>{this.renderLegends(legend.split(/\r?\n/))}</LegendContainer>
          </Visible>
        </Cap>
      </KeyOuterComponent>
    );
  }
}

export default hot(AnimatedKeyCap);
