import React, { Component } from 'react';
import styled from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { GlobalStyle, pageColors, baseFontStyle, breakpoints, containerWidths, easeHard } from 'styles';
import { setConfiguration, Container, Row, Col, ScreenClassProvider } from 'react-grid-system';
import { ParallaxProvider } from 'react-scroll-parallax';
import FollowUp from '../FollowUp/FollowUp';
import Progress from '../Progress/Progress';
import AboutKeyBoard from '../AboutKeyBoard/AboutKeyboard';
import Header from '../Header/Header';

setConfiguration({
  defaultScreenClass: 'sm',
  gridColumns: 15,
  gutterWidth: 6,
  containerWidths: Object.entries(containerWidths).map(cw => cw[1]),
  breakpoints: Object.entries(breakpoints).map(bp => bp[1]),
});

const MainPageContainer = styled.div`
    ${baseFontStyle}
    background-color: ${pageColors.dark1};
    width: 100%;
    height: 100%;
`;

class Home extends Component {
  componentDidMount() {
    document.title = 'futur.click';
  }

  render() {
    return (
      <ParallaxProvider>
        <ScreenClassProvider>
          <MainPageContainer>
            <GlobalStyle />
            <Header />
            <AboutKeyBoard />
            <Progress />
            <FollowUp />
          </MainPageContainer>
        </ScreenClassProvider>
      </ParallaxProvider>
    );
  }
}

export default hot(Home);
